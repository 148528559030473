import React, { useState, useEffect } from 'react';
import './CommercialPopup.css'; // Custom styles for popup

const CommercialPopup = ({ images, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Handle advancing through the images
  useEffect(() => {
    if (currentIndex < images.length) {
      const timer = setTimeout(() => {
        setCurrentIndex(currentIndex + 1);
      }, 3000); // Adjust time (in ms) for how long each image stays
      return () => clearTimeout(timer);
    } else {
      // Automatically close the popup when all images have been displayed
      onClose();
    }
  }, [currentIndex, images.length, onClose]);

  // Handle close button click
  const handleClose = () => {
    onClose();
  };

  // If the current index exceeds the number of images, don't show anything
  if (currentIndex >= images.length) {
    return null;
  }

  return (
    <div className="commercial-popup-overlay">
      <div className="commercial-popup-content">
        <img src={images[currentIndex]} alt="Commercial" />
        <button className="close-btn" onClick={handleClose}>Skip</button>
      </div>
    </div>
  );
};

export default CommercialPopup;
