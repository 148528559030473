import React from 'react';
import './LinkPreviews.css'; // Assuming you have a CSS file for styling

const LinkPreviews = () => {
  const previewStyle = {
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '10px',
    marginBottom: '10px',
    textAlign: 'left',
  };

  // Array of eBay items with local images
  const ebayItems = [
    // {
    //   id: 1,
    //   title: 'Flawless - #1 Babe Ruth',

    //   imageUrl: 'images/babe.webp',
    //   link: 'https://www.ebay.com/itm/375580008311?epid=7055863093&itmmeta=01J59H8BQYFC6H02GCSW7NJGMR&hash=item5772501f77:g:658AAOSwI55moUVz',
    // },

    {
      id: 3,
      title: '2023 Mosaic CJ Stroud Rookie',

      imageUrl: 'images/stroud.webp',
      link: 'https://www.ebay.com/sch/i.html?item=375556738327&rt=nc&_trksid=p4429486.m3561.l161211&_ssn=twinpyros',
    },
    {
      id: 4,
      title: '2017 Panini Patrick Mahomes II',

      imageUrl: 'images/patrick.webp',
      link: 'https://www.ebay.com/sch/i.html?item=375556738327&rt=nc&_trksid=p4429486.m3561.l161211&_ssn=twinpyros',
    },
    // {
    //   id: 5,
    //   title: 'Mikal Bridges 2018-19 Rookie',

    //   imageUrl: 'images/bridges.webp',
    //   link: 'https://www.ebay.com/itm/375566068562?epid=10056589282&itmmeta=01J4A4QZGFVGRE2Q13T6FN2Y7B&hash=item57717b6b52:g:JoMAAOSwwqNmqj4F',
    // },
    {
      id: 6,
      title: 'C.J. GRAHAM ~ AUTOGRAPHED',

      imageUrl: 'images/cjg.webp',
      link: 'https://www.ebay.com/sch/i.html?item=375556738327&rt=nc&_trksid=p4429486.m3561.l161211&_ssn=twinpyros',
    },
    {
      id: 2,
      title: 'See All Our Live eBay Auctions!',

      imageUrl: 'images/image0.webp',
      link: 'https://www.ebay.com/sch/i.html?item=375556738327&rt=nc&_trksid=p4429486.m3561.l161211&_ssn=twinpyros',
    },


    // Add more items as needed
  ];

  return (
    <div>
      
      <div style={previewStyle} className="header-container">
        <button className="facebook-button">
          <a
            href="https://www.facebook.com/people/Twin-Pyros-Fireworks-LLC/61556015133066/?mibextid=ZbWKwL"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f"></i>Facebook
          </a>
        </button>
        {/* <button className="store-button">
          <a
            href="/store"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f"></i>CYBER STORE - ONLINE SHOPPING!
          </a>
        </button> */}
        <button className="ebay-button">
                <a
                  href='https://www.ebay.com/sch/i.html?item=375556738327&rt=nc&_trksid=p4429486.m3561.l161211&_ssn=twinpyros'
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f"></i>Twin Pyros eBay
          </a>
        </button>
        <button className="store-button">
                <a
                  href='/#map'
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f"></i>Visit Twin Pyros' Brazil, IN Location!
          </a>
        </button>

      </div>
      <div className="ebay-items-container">
        {ebayItems.map((item) => (
          <div key={item.id} style={previewStyle} className="ebay-item">
            <img src={item.imageUrl} alt={item.title} />
            <div className="ebay-item-details">
              <h3>{item.title}</h3>
              <p>{item.price}</p>
              <button className="ebay-button">
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View eBay Item
                </a>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LinkPreviews;