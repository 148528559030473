
import imageData from './image_config.json';



const MloadImages = () => {
  // Assuming imageData structure contains keys like Cimages, HotCimages, etc.
  const regularPhotos = imageData.Mimages.map(url => ({ src: url, name: url.split('/').pop() }));
  const hotPhotos = imageData.HotMimages.map(url => ({ src: url, name: url.split('/').pop(), hot: true }));

  // Interspersing hot photos into regular photos
  const interspersedPhotos = [];
  const hotPhotosLength = hotPhotos.length;
  const interval = Math.ceil(regularPhotos.length / (hotPhotosLength + 1));

  let hotIndex = 0;
  for (let i = 0; i < regularPhotos.length; i++) {
    if (hotIndex < hotPhotosLength && i % interval === 0) {
      interspersedPhotos.push(hotPhotos[hotIndex]);
      hotIndex++;
    }
    interspersedPhotos.push(regularPhotos[i]);
  }

  return interspersedPhotos;
};

export default MloadImages; 
     