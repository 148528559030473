
// Navbar.js
import React, { useState } from 'react';
import './navbar.css'; // Make sure to import your Halloween-themed CSS file
import { HashLink as Link } from 'react-router-hash-link';

const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <button className="menu-button halloween-menu-button" onClick={toggleModal}>
        Menu
      </button>
      {isModalOpen && (
        <div className="modal-backdrop halloween-modal-backdrop" onClick={toggleModal}>
          <div className="modal-content halloween-modal-content" onClick={(e) => e.stopPropagation()}>
            <ul className="modal-list halloween-modal-list">
              <li className="modal-item halloween-modal-item">
                <Link to="/" className="modal-link halloween-modal-link" onClick={toggleModal}>Home</Link>
              </li>
              <li className="modal-item halloween-modal-item">
                <Link to="/Cproducts" className="modal-link halloween-modal-link" onClick={toggleModal}>Collectibles</Link>
              </li>
              <li className="modal-item halloween-modal-item">
                <Link to="/Hproducts" className="modal-link halloween-modal-link" onClick={toggleModal}>Costumes, etc...</Link>
              </li>
              <li className="modal-item halloween-modal-item">
                <Link to="/FWproducts" className="modal-link halloween-modal-link" onClick={toggleModal}>Fireworks</Link>
              </li>
              <li className="modal-item halloween-modal-item">
                <Link to="/Mproducts" className="modal-link halloween-modal-link" onClick={toggleModal}>Memorabilia</Link>
              </li>
              <li className="modal-item halloween-modal-item">
                <Link to="/Xproducts" className="modal-link halloween-modal-link" onClick={toggleModal}>Novelties</Link>
              </li>
              <li className="modal-item halloween-modal-item">
                <Link to="/store" className="modal-link halloween-modal-link" onClick={toggleModal}>Online Cyber Store!</Link>
              </li>
              <li className="modal-item halloween-modal-item">
                <Link to="/#business-info" className="modal-link halloween-modal-link" onClick={toggleModal}>Visit Us!</Link>
              </li>
              <li className="modal-item halloween-modal-item">
                <Link to="/" className="modal-link halloween-modal-link" onClick={toggleModal}> ... </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;

// import React, { useState } from 'react';
// import './navbar.css'; // Make sure to import your CSS file
// import { HashLink as Link } from 'react-router-hash-link';

// const Navbar =() => {
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };
 
//   return (
//     <>
//       <button className="menu-button" onClick={toggleModal}>
//         Menu
//       </button>
//       {isModalOpen && (
//         <div className="modal-backdrop" onClick={toggleModal}>
//           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//             <ul className="modal-list">
//               <li className="modal-item">
//                 <Link to="/" className="modal-link" onClick={toggleModal}>Home</Link>
//               </li>
//               <li className="modal-item">
//                 <Link to="/Cproducts" className="modal-link" onClick={toggleModal}>Collectibles</Link>
//               </li>
//               <li className="modal-item">
//                 <Link to="/Hproducts" className="modal-link" onClick={toggleModal}>Costumes, etc...</Link>
//               </li>
//               <li className="modal-item">
//                 <Link to="/FWproducts" className="modal-link" onClick={toggleModal}>Fireworks</Link>
//               </li>
//               <li className="modal-item">
//                 <Link to="/Mproducts" className="modal-link" onClick={toggleModal}>Memorabilia</Link>
//               </li>
//               <li className="modal-item">
//                 <Link to="/Xproducts" className="modal-link" onClick={toggleModal}>Novelties</Link>
//               </li>
//               <li className="modal-item">
//                 <Link to="/#photo-section" className="modal-link" onClick={toggleModal}>More Great Products!</Link>
//               </li>
//               <li className="modal-item">
//                 <Link to="/#business-info" className="modal-link" onClick={toggleModal}>Visit Us!</Link>
//               </li>
//               <li className="modal-item">
//                 <Link to="/" className="modal-link" onClick={toggleModal}> ... </Link>
//               </li>
//             </ul>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default Navbar;
