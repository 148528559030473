
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./StoreList.css";

export default function StoreList() {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quantities, setQuantities] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({}); // Track selected options

  useEffect(() => {
    const fetchProducts = async () => {
      const token = process.env.REACT_APP_API_TOKEN;
      const headers = { Authorization: `Token ${token}` };

      try {
        const response = await axios.get("https://www.animpairedlove.org/catalogs/api/list/", { headers });
        if (Array.isArray(response.data)) {
          setProducts(response.data);
        } else {
          setError("Received data is not an array");
        }
      } catch (err) {
        setError("Failed to fetch products");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();

    // Handle success or cancel query parameters
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      alert("Order placed successfully!");
    }
    if (query.get("canceled")) {
      alert("Order canceled. You can continue shopping.");
    }
  }, []);

  const handleQuantityChange = (productId, quantity) => {
    setQuantities({ ...quantities, [productId]: quantity });
  };

  const handleOptionChange = (productId, option) => {
    setSelectedOptions({ ...selectedOptions, [productId]: option });
  };

  const [notification, setNotification] = useState("");


  const addToCart = (product) => {
    const quantity = quantities[product.ID] || 1;
    const option = selectedOptions[product.ID] || null;

    setCart((prevCart) => {
      const existingItem = prevCart.find(
        (item) => item.ID === product.ID && item.option === option
      );

      if (existingItem) {
        return prevCart.map((item) =>
          item.ID === product.ID && item.option === option
            ? { ...item, quantity: item.quantity + quantity }
            : item
        );
      } else {
        return [...prevCart, { ...product, quantity, option }];
      }
    });

    // Set notification
    setNotification(`"${product.title}" added to cart!`);
    // Clear notification after 3 seconds
    setTimeout(() => setNotification(""), 2000);

  };

  // Function to remove an item from the cart
  const removeFromCart = (productId, option) => {
    setCart((prevCart) =>
      prevCart.filter((item) => !(item.ID === productId && item.option === option))
    );
  };
  
  const handleCheckout = async () => {
    try {
      const response = await axios.post("https://www.animpairedlove.org/catalogs/api/create-checkout-session/", {
        cart: cart,
      });
      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error("Invalid session URL");
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      alert("Failed to initiate checkout. Please try again.");
    }
  };

  if (loading) return <p>Loading products...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="store-list-container">
      {notification && (
        <div className="notification">
          {notification}
        </div>
      )}

      <div className="store-list">
        <h2>Available Now!</h2>
        <ul className="product-list">
          {products.map((product) => (
            <li key={product.ID} className="product-item">
              <img src={product.image_src} alt={product.image_alt_text || "Product Image"} />
              <h3>{product.title}</h3>
              <p>Price: ${product.cost_per_item}</p>
              <p>In Stock: {product.inventory_qty}</p>
              
              {/* Render the options dropdown if the product has options */}
              {product.options && product.options.length > 0 && (
                <div>
                  <label htmlFor={`option-select-${product.ID}`}>Choose an option:</label>
                  <select
                    id={`option-select-${product.ID}`}
                    onChange={(e) => handleOptionChange(product.ID, e.target.value)}
                    value={selectedOptions[product.ID] || ""}
                  >
                    <option value="">Select...</option>
                    {product.options.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <input
                type="number"
                min="1"
                max={product.inventory_qty}
                value={quantities[product.ID] || 1}
                onChange={(e) => handleQuantityChange(product.ID, parseInt(e.target.value))}
              />
              <button onClick={() => addToCart(product)}>Add to Cart</button>
            </li>
            
          ))}
        </ul>
      </div>

      <div className="cart-side-panel">
        <h2>Your Cart</h2>
        {cart.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          cart.map((item, index) => (
            <div key={index} className="cart-item">
              <h4>{item.title}</h4>
              {item.option && <p>Option: {item.option}</p>}
              <p>Quantity: {item.quantity}</p>
              <p>Price: ${item.cost_per_item * item.quantity}</p>
              {/* Add a "Remove" button */}
              <button onClick={() => removeFromCart(item.ID, item.option)}>Remove</button>
            
            </div>
          ))
        )}
        <p>Total: ${cart.reduce((total, item) => total + item.cost_per_item * item.quantity, 0)}</p>
        <button className="buy-now-button" onClick={handleCheckout}>
          Buy Now
        </button>
      </div>
    </div>
  );
}
